/* eslint-disable @stripe-internal/embedded/no-restricted-globals */
import {CONNECT_ELEMENTS_OVERLAY_ZINDEX_DEFAULT} from '@stripe-internal/embedded-theming';
import {serializeError} from 'serialize-error';
import type {IAnalytics, IReports, Tags} from '@sail/observability';
import {urlRedirect} from '@stripe-internal/safe-links';
import type {
  CollectBankAccountTokenResult,
  FinancialConnectionsSessionResult,
} from '@stripe/stripe-js';
import {buildAccessoryLayerFrame} from './accessory-layer-frame/buildAccessoryLayerFrame';
import {buildStripeJsLayerFrame} from './stripejs-layer-frame/buildStripeJsLayerFrame';
import type {ConnectJsOptions} from '../connect/ConnectJsOptions';
import type {
  IFromUILayerMessage,
  IToUILayerMessage,
  IUILayerInit,
  UILayerInitOptions,
} from './UILayerMessages';
import type {
  IAccessoryLayerInit,
  IFromAccessoryLayerMessage,
} from './accessory-layer-frame/AccessoryLayerMessages';
import type {
  IStripeJsLayerInit,
  IStripeJsCollectFinancialConnectionsAccountsComplete,
  IStripeJsCollectBankAccountTokenComplete,
} from './stripejs-layer-frame/StripeJsLayerMessages';
import {getCurrentScriptUrlContext} from '../utils/getCurrentScriptUrlContext';
import {calculatePosition} from './utils/calculatePosition';
import type {EmitEvent} from '../connect/ConnectElementEventEmitter';
import type {
  IOnboardingState,
  OnboardingStateValues,
} from '../data-layer-client/OnboardingContext/OnboardingState';
import type {AccountSessionClaim} from '../data-layer-frame/types';
import {setupMessagePortListener} from './utils/messageChannel';
import type {UpdateOptions} from '../connect/ConnectJSInterface/InitAndUpdateOptionsTypes';
import {getDevLogger} from '../utils/getLogger';
import type {ConnectElementImportKeys} from '../connect/ConnectJSInterface/ConnectElementList';
import {CONNECT_ELEMENT_IMPORTS} from '../connect/ConnectJSInterface/ConnectElementList';
import type {IFrameMessenger} from '../data-layer-client/FrameMessenger';
import type {IframeLayer} from './utils/IframeErrorReporter';
import {createSentryErrorAndAnalyticsSender} from './utils/IframeErrorReporter';
import type {
  ConnectElementSupplementalFunctions,
  IConnectElementSupplementalFunction,
  SupplementalFunctionKey,
} from '../connect/ConnectElementSupplementalFunctions';
import {PlatformLayerSupplementalFunctionNotImplementedError} from './errors';
import type {IConnectElementSupplementalObject} from '../connect/ConnectElementSupplementalObjects';
import {getStronglyTypedEntries} from '../utils/getStronglyTypedEntries';
import {removeSearchParam} from '../utils/removeSearchParam';
import {
  UI_LAYER_MESSAGE_CHANNEL_TIMEOUT,
  UI_LAYER_RENDER_TIMEOUT,
} from '../connect/utils/embeddedLoadErrors';
import type {LoggedOutStateValues} from '../connect/LoggedOutState';
import {getSafeAreaDimensions, safeAreaExists} from './utils/mobileUtils';

const devLogger = getDevLogger();

export default class ConnectElementBaseMessageChannel {
  /** The message channel used to communicate between the platform frame (ConnectElementBaseMessageChannel) and the message port on the UILayerWrapper. */
  private uiLayerMessageChannel: MessageChannel = new MessageChannel();

  /** The map of accessory layer frame names to message channels (which are used to communicate between the platform frame and the corresponding accessory layer). */
  private accessoryLayerMessageChannels: Record<string, MessageChannel> = {};

  /** The map of StripeJS layer frame names to message channels (which are used to communicate between the platform frame and the corresponding StripeJS layer). */
  private stripeJsLayerMessageChannels: Record<string, MessageChannel> = {};

  /** The window object of the corresponding UI Layer iframe. This is used to post an `init` message to the UI Layer. */
  private uiLayerWindow: Window;

  /** The target origin of the UI Layer iframe for which to send an init postMessage. */
  private scriptOrigin: string = getCurrentScriptUrlContext().origin;

  /** frame ids created by UI Layer that we can destroy */
  private createdFrameIds: Record<string, string[]> = {};

  /** The function which sends an error report to the Sentry instance. */
  private captureIframeException: (
    error: Error,
    iframeLayer: IframeLayer,
  ) => void;

  /**
   * Creates a wrapper over the message channel used to communicate between the platform frame (ConnectElementBaseMessageChannel)
   * and the message port on the UILayerWrapper.
   * @param iframe The iframe of the UI Layer frame.
   * @param emitEvent The function that emits event when receiving an event message from the uiControllerMessagePort.
   * @param onboardingState The onboarding state of the embedded component.
   * @param connectJsOptions The ConnectJS options of the embedded component.
   * @param connectElement The name of the embedded component.
   * @param frameMessenger The FrameMessenger instance of the Connect instance, for communicating with the data_layer iframe.
   */
  constructor(
    iframe: HTMLIFrameElement,
    emitEvent: EmitEvent,
    onboardingState: IOnboardingState,
    connectJsOptions: ConnectJsOptions,
    private connectElement: ConnectElementImportKeys,
    private frameMessenger: IFrameMessenger,
    private supplementalFunctions: ConnectElementSupplementalFunctions,
    isDisconnected: boolean,
    analytics: IAnalytics,
    reports: IReports,
  ) {
    this.captureIframeException = createSentryErrorAndAnalyticsSender(
      (error: Error) => {
        reports.error(error, {
          project: CONNECT_ELEMENT_IMPORTS[this.connectElement].teamOwner,
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          tags: this.frameMessenger.getAuthMetadata() as Tags,
        });
      },
      analytics,
      this.connectElement,
    );

    if (!iframe.contentWindow) {
      // There is a possibility that the iframe may be killed off, e.g. in a SPA/client-side navigation
      // when the user navigates from a page that has the embedded component to another page.
      // Hence, the ConnectJS code may still be running - this check is meant to ensure that the
      // 'UI layer frame is no longer in the DOM' error is safe to ignore.
      if (!document.body.contains(iframe)) {
        if (isDisconnected) {
          // We don't report to Sentry since this behavior is expected
          throw new Error('Custom element was disconnected');
        } else {
          const error = new Error('UI layer frame is no longer in the DOM');
          this.captureIframeException(error, 'ui-layer');
          throw error;
        }
      } else {
        const error = new Error('UI layer frame not loaded');
        this.captureIframeException(error, 'ui-layer');
        throw error;
      }
    }
    this.uiLayerWindow = iframe.contentWindow;

    // These timeouts will trigger a load error to be emitted if the UI Layer does not respond within the specified time
    const uiLayerMessageChannelFailureTimeout = setTimeout(() => {
      const error = new Error(
        `UI layer message channel was not initialized within ${UI_LAYER_MESSAGE_CHANNEL_TIMEOUT}ms`,
      );

      const errorType = 'api_error';
      emitEvent('_internal_loaderror', {error, type: errorType});
      emitEvent('loaderror', {
        error: {type: errorType, message: error.message},
      });
    }, UI_LAYER_MESSAGE_CHANNEL_TIMEOUT);

    // This is set after the UI Layer message channel is initialized
    let uiLayerRenderFailureTimeout: NodeJS.Timeout | undefined;

    this.uiLayerMessageChannel.port1.onmessage = async (event: MessageEvent) =>
      setupMessagePortListener<IFromUILayerMessage>({
        event,
        messageDirection: 'from',
        messageType: 'ui-layer-message',
        messageHandlers: {
          // This event calls the refreshClientSecretCallback via the platform frame's FrameMessenger instance
          refreshclientsecretfrom: async ({messageId}) => {
            await this.frameMessenger.refreshClientSecret.refresh();
            this.sendMessageToUILayer('refreshclientsecretto', {messageId});
          },
          callsupplementalfunction: async (props) => {
            const funcToCall =
              this.supplementalFunctions.values[props.functionKey];

            if (!funcToCall) {
              // We send a reply with an error if the ufnction is not defined
              this.sendMessageToUILayer('supplementalfunctioncallfinished', {
                messageId: props.messageId,
                serializableError: serializeError(
                  new PlatformLayerSupplementalFunctionNotImplementedError(
                    `The supplemental function '${props.functionKey}' is not defined`,
                  ),
                ),
              });
              return;
            }

            try {
              const response = await funcToCall(props.values[0]);
              this.sendMessageToUILayer('supplementalfunctioncallfinished', {
                messageId: props.messageId,
                values: response,
              });
            } catch (error) {
              this.sendMessageToUILayer('supplementalfunctioncallfinished', {
                messageId: props.messageId,
                serializableError: serializeError(error),
              });
            }
          },
          // This event deletes the accessory layer iframe that is appended to the platform's document.body
          deleteaccessorylayer: ({id}) => {
            const messageChannelToDelete =
              this.accessoryLayerMessageChannels[id];
            if (messageChannelToDelete) {
              messageChannelToDelete.port1.close();
              messageChannelToDelete.port2.close();
              delete this.accessoryLayerMessageChannels[id];
            }

            const frameToDelete = document.querySelector(
              `iframe[name="${id}"]`,
            );
            if (frameToDelete) {
              frameToDelete.remove();
            }
            this.sendMessageToUILayer('deleteaccessorylayer', {id});
          },

          // This event updates the ConnectJS options on the platform frame when the options change on the UI layer
          updateinitoptionsfrom: (values) => {
            connectJsOptions.updateValues(values);
          },

          // This event is used to create a StripeJS iframe and sets up a corresponding message channel
          collectfinancialconnectionsaccounts: async ({
            id,
            clientSecret,
            locale,
            merchantId,
            publishableKey,
            stripeJsEndpoint,
          }) => {
            if (connectJsOptions.getValues().metaOptions.mobileSdk) {
              emitEvent('mobilefinancialconnectionsrequested', {
                clientSecret,
                id,
                connectedAccountId: merchantId,
              });
            } else {
              const stripeJsLayer = await buildStripeJsLayerFrame(
                id,
                connectJsOptions.getValues().appearance.variables
                  ?.overlayZIndex || CONNECT_ELEMENTS_OVERLAY_ZINDEX_DEFAULT,
                this.connectElement,
              );

              const stripeJsLayerMessageChannel = new MessageChannel();
              this.stripeJsLayerMessageChannels[id] =
                stripeJsLayerMessageChannel;

              // Initialize accessory layer message channel
              const stripeJsLayerInitMessage: IStripeJsLayerInit = {
                requestType: 'initstripejslayer',
                direction: 'to',
                type: 'stripejs-layer-message',
              };
              stripeJsLayer.postMessage(
                stripeJsLayerInitMessage,
                getCurrentScriptUrlContext().origin,
                [stripeJsLayerMessageChannel.port2],
              );

              stripeJsLayerMessageChannel.port1.postMessage({
                type: 'stripejs-layer-message',
                requestType: 'collectfinancialconnectionsaccounts',
                direction: 'to',
                values: {
                  id,
                  clientSecret,
                  locale,
                  merchantId,
                  publishableKey,
                  stripeJsEndpoint,
                },
              });

              stripeJsLayerMessageChannel.port1.onmessage = (event) =>
                setupMessagePortListener<IStripeJsCollectFinancialConnectionsAccountsComplete>(
                  {
                    event,
                    messageDirection: 'from',
                    messageType: 'stripejs-layer-message',
                    messageHandlers: {
                      collectfinancialconnectionsaccountscomplete: (values) => {
                        this.sendMessageToUILayer(
                          'collectfinancialconnectionsaccountsresult',
                          values,
                        );
                      },
                    },
                  },
                );
              stripeJsLayerMessageChannel.port1.onmessageerror = () => {};
            }
          },

          collectbankaccounttoken: async ({
            id,
            clientSecret,
            locale,
            merchantId,
            publishableKey,
            stripeJsEndpoint,
          }) => {
            if (connectJsOptions.getValues().metaOptions.mobileSdk) {
              emitEvent('mobilefinancialconnectionsrequested', {
                clientSecret,
                id,
                connectedAccountId: merchantId,
              });
            } else {
              const stripeJsLayer = await buildStripeJsLayerFrame(
                id,
                connectJsOptions.getValues().appearance.variables
                  ?.overlayZIndex || CONNECT_ELEMENTS_OVERLAY_ZINDEX_DEFAULT,
                this.connectElement,
              );
              const stripeJsLayerMessageChannel = new MessageChannel();
              this.stripeJsLayerMessageChannels[id] =
                stripeJsLayerMessageChannel;

              // Initialize accessory layer message channel
              const stripeJsLayerInitMessage: IStripeJsLayerInit = {
                requestType: 'initstripejslayer',
                direction: 'to',
                type: 'stripejs-layer-message',
              };
              stripeJsLayer.postMessage(
                stripeJsLayerInitMessage,
                getCurrentScriptUrlContext().origin,
                [stripeJsLayerMessageChannel.port2],
              );

              stripeJsLayerMessageChannel.port1.postMessage({
                type: 'stripejs-layer-message',
                requestType: 'collectbankaccounttoken',
                direction: 'to',
                values: {
                  id,
                  clientSecret,
                  locale,
                  merchantId,
                  publishableKey,
                  stripeJsEndpoint,
                },
              });

              stripeJsLayerMessageChannel.port1.onmessage = (event) =>
                setupMessagePortListener<IStripeJsCollectBankAccountTokenComplete>(
                  {
                    event,
                    messageDirection: 'from',
                    messageType: 'stripejs-layer-message',
                    messageHandlers: {
                      collectbankaccounttokencomplete: (values) => {
                        this.sendMessageToUILayer(
                          'collectbankaccounttokenresult',
                          values,
                        );
                      },
                    },
                  },
                );
              stripeJsLayerMessageChannel.port1.onmessageerror = () => {};
            }
          },

          // This event is used to create an accessory layer iframe and sets up a corresponding message channel
          requestaccessorylayer: async ({
            id,
            type,
            sourceId,
            initialReposition,
          }) => {
            this.createdFrameIds[sourceId] =
              this.createdFrameIds[sourceId] || [];
            this.createdFrameIds[sourceId].push(id);

            const accessoryLayer = await buildAccessoryLayerFrame(
              id,
              type,
              connectJsOptions.getValues().appearance.variables
                ?.overlayZIndex || CONNECT_ELEMENTS_OVERLAY_ZINDEX_DEFAULT,
              this.connectElement,
            );

            if (initialReposition) {
              const {attachmentPoints, placement, sourceId, flip} =
                initialReposition;

              const sourceFrame = sourceId.startsWith('stripe-connect-ui-layer')
                ? iframe
                : // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                  (document.querySelector(
                    `iframe[name="${sourceId}"]`,
                  ) as HTMLIFrameElement);

              const sourceFrameDimensions = sourceFrame.getBoundingClientRect();
              const isFixedFrame =
                getComputedStyle(sourceFrame).position === 'fixed';
              const safeAreaDimensions = getSafeAreaDimensions();

              const positionStyle = calculatePosition({
                placement,
                attachmentPoints,
                isFixedFrame,
                // If the souce iframe's position is fixed, it does not scroll, e.g. opening an overlay.
                // Hence, we do not want to use the `window.scroll` as we are calculaing the top and left positions
                // without needing to account for scroll.
                // However, if we have a nested popover and the source popover scrolls, this would be incorrect as we would be
                // using the platform's window object instead of the source frame's window object. Nonetheless, this technically
                // should not occur as the popover and tooltip never scroll.
                sourceFrameDimensions: {
                  offsetLeft: isFixedFrame
                    ? sourceFrame.offsetLeft
                    : sourceFrameDimensions.left + window.scrollX,
                  offsetTop: isFixedFrame
                    ? sourceFrame.offsetTop
                    : sourceFrameDimensions.top + window.scrollY,
                },
                accessoryFrameDimensions: {
                  offsetWidth: 0,
                  offsetHeight: 0,
                  scrollHeight: 0,
                  scrollWidth: 0,
                  scrollLeft: 0,
                  scrollTop: 0,
                },
                safeAreaDimensions: safeAreaExists(safeAreaDimensions)
                  ? safeAreaDimensions
                  : undefined,
                flip,
              });

              // Only adjust the top and left positions if it is the initial reposition
              if (positionStyle.top) {
                accessoryLayer.style.top = positionStyle.top;
              }
              if (positionStyle.left) {
                accessoryLayer.style.left = positionStyle.left;
              }
            }

            const accessoryLayerMessageChannel = new MessageChannel();
            this.accessoryLayerMessageChannels[id] =
              accessoryLayerMessageChannel;

            if (!accessoryLayer.contentWindow) {
              const error = new Error(`Accessory layer ${id} failed to load`);
              this.captureIframeException(error, 'accessory-layer');
              throw error;
            }

            // Initialize accessory layer message channel
            const accessoryLayerInitMessage: IAccessoryLayerInit = {
              requestType: 'initaccessorylayer',
              direction: 'to',
              type: 'accessory-layer-message',
            };
            accessoryLayer.contentWindow.postMessage(
              accessoryLayerInitMessage,
              getCurrentScriptUrlContext().origin,
              [accessoryLayerMessageChannel.port2],
            );

            accessoryLayerMessageChannel.port1.onmessage = (event) =>
              setupMessagePortListener<IFromAccessoryLayerMessage>({
                event,
                messageDirection: 'from',
                messageType: 'accessory-layer-message',
                messageHandlers: {
                  resizeiframe: ({styles}) => {
                    // Apply all of the requested styles
                    Object.entries(styles ?? {}).forEach(([key, value]) => {
                      // @ts-expect-error - we don't know all the possible combinations of key and value
                      accessoryLayer.style[key] = value;
                    });
                  },
                },
              });
            accessoryLayerMessageChannel.port1.onmessageerror = () => {};

            this.sendMessageToUILayer('createdaccessorylayer', {id});
          },

          // This event is used to resize and reposition the ui layer or accessory iframes according to the HTML element positioning in the platform frame.
          readjustiframe: ({id, iframeStyles, resize, reposition}) => {
            const isUILayer = id.startsWith('stripe-connect-ui-layer');
            const readjustedFrame = isUILayer
              ? iframe
              : // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                (document.querySelector(
                  `iframe[name="${id}"]`,
                ) as HTMLIFrameElement);

            if (!(readjustedFrame instanceof HTMLIFrameElement)) {
              devLogger.error('Failed to find frame (readjustiframe): ', id);
              return;
            }

            // Apply iframe styles
            Object.entries(iframeStyles ?? {}).forEach(([key, value]) => {
              // @ts-expect-error - we don't know all the possible combinations of key and value
              readjustedFrame.style[key] = value;
            });

            // Resize iframe
            if (resize) {
              Object.entries(resize.styles ?? {}).forEach(([key, value]) => {
                // @ts-expect-error - we don't know all the possible combinations of key and value
                readjustedFrame.style[key] = value;
              });
            }

            // Reposition iframe
            if (reposition) {
              const {attachmentPoints, placement, sourceId, flip} = reposition;

              const sourceFrame = sourceId.startsWith('stripe-connect-ui-layer')
                ? iframe
                : // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                  (document.querySelector(
                    `iframe[name="${sourceId}"]`,
                  ) as HTMLIFrameElement);

              const sourceFrameDimensions = sourceFrame.getBoundingClientRect();
              const isFixedFrame =
                getComputedStyle(sourceFrame).position === 'fixed';
              const safeAreaDimensions = getSafeAreaDimensions();

              const positionStyle = calculatePosition({
                placement,
                attachmentPoints,
                isFixedFrame,
                // If the souce iframe's position is fixed, it does not scroll, e.g. opening an overlay.
                // Hence, we do not want to use the `window.scroll` as we are calculaing the top and left positions
                // without needing to account for scroll.
                // However, if we have a nested popover and the source popover scrolls, this would be incorrect as we would be
                // using the platform's window object instead of the source frame's window object. Nonetheless, this technically
                // should not occur as the popover and tooltip never scroll.
                sourceFrameDimensions: {
                  offsetLeft: isFixedFrame
                    ? sourceFrame.offsetLeft
                    : sourceFrameDimensions.left + window.scrollX,
                  offsetTop: isFixedFrame
                    ? sourceFrame.offsetTop
                    : sourceFrameDimensions.top + window.scrollY,
                },
                accessoryFrameDimensions: {
                  offsetWidth: readjustedFrame.offsetWidth,
                  offsetHeight: readjustedFrame.offsetHeight,
                  scrollHeight: readjustedFrame.scrollHeight,
                  scrollWidth: readjustedFrame.scrollWidth,
                  scrollLeft: readjustedFrame.scrollLeft,
                  scrollTop: readjustedFrame.scrollTop,
                },
                flip,
                safeAreaDimensions: safeAreaExists(safeAreaDimensions)
                  ? safeAreaDimensions
                  : undefined,
              });

              Object.entries(positionStyle).forEach(([key, value]) => {
                // @ts-expect-error - we don't know all the possible combinations of key and value
                readjustedFrame.style[key] = value;
              });
            }

            // Even though the containing iframe has negative margin, the component's size needs to be
            // set explicitly to avoid taking up extra space at the bottom or right in some cases.
            if (isUILayer) {
              const componentDiv = iframe.parentElement;
              if (componentDiv) {
                componentDiv.style.height = `calc(${iframe.style.height} - 8px)`;
                componentDiv.style.width = `calc(${iframe.style.width} - 8px)`;
              }
            }
          },

          // This event is used to emit a HTML event when the HTML attribute on the custom element changes
          emithtmlevent: ({eventType, detail}) => {
            emitEvent(eventType, detail);
          },

          // This event updates the onboarding state on the platform frame when the UI layer's onboarding state changes
          updateonboardingstate: (values) => {
            if (
              onboardingState.values.isOnboardingComplete ===
              values.isOnboardingComplete
            ) {
              // This is a no-op
              return;
            }

            onboardingState.updateValues(values);
          },

          // This event deletes the StripeJS iframe that is appended to the platform's document.body
          deletestripejslayer: ({id}) => {
            const stripeJsMessageChannelToDelete =
              this.stripeJsLayerMessageChannels[id];
            if (stripeJsMessageChannelToDelete) {
              stripeJsMessageChannelToDelete.port1.close();
              stripeJsMessageChannelToDelete.port2.close();
              delete this.stripeJsLayerMessageChannels[id];
            }

            const stripeJsFrameToDelete = document.querySelector(
              `iframe[name="${id}"]`,
            );
            if (stripeJsFrameToDelete) {
              stripeJsFrameToDelete.remove();
            }
          },

          // This event copies the text from the UI layer to the platform frame's clipboard
          copyText: (val: string) => {
            navigator.clipboard.writeText(val);
          },

          // This event reloads the platform frame's page when the UI layer message sends a message to it
          reloadWindow: () => {
            window.location.reload();
          },

          // This event remove the search param from the platform frame's url when the UI layer message sends a message to with the param name
          removeSearchParam: (param: string) => {
            removeSearchParam(param);
          },

          // This event redirects the platform frame's window when the UI layer message sends a message to it
          redirectWindow: (url: string) => {
            urlRedirect(url);
          },

          reportsuccess: (successType) => {
            switch (successType) {
              case 'messagePort':
                clearTimeout(uiLayerMessageChannelFailureTimeout);

                // Setup the timeout for the UI Layer to render after the message channel is initialized
                uiLayerRenderFailureTimeout = setTimeout(() => {
                  const error = new Error(
                    `UI layer did not render within ${UI_LAYER_RENDER_TIMEOUT}ms`,
                  );

                  const errorType = 'render_error';
                  emitEvent('_internal_loaderror', {error, type: errorType});
                  emitEvent('loaderror', {
                    error: {type: errorType, message: error.message},
                  });
                }, UI_LAYER_RENDER_TIMEOUT);

                break;
              case 'render':
                clearTimeout(uiLayerRenderFailureTimeout);
                break;
              default:
                devLogger.error(
                  `Unknown success type received from UI Layer: ${successType}`,
                );
            }
          },
        },
      });

    this.uiLayerMessageChannel.port1.onmessageerror = () => {};
  }

  private sendMessageToUILayer<T extends IToUILayerMessage>(
    requestType: T['requestType'],
    values?: T extends {values: unknown} ? T['values'] : undefined,
  ) {
    this.uiLayerMessageChannel.port1.postMessage({
      type: 'ui-layer-message',
      direction: 'to',
      requestType,
      values,
    });
  }

  initUILayer(values: UILayerInitOptions) {
    const initMessage: IUILayerInit = {
      type: 'ui-layer-message',
      requestType: 'init',
      direction: 'to',
      values,
    };

    this.uiLayerWindow.postMessage(initMessage, this.scriptOrigin, [
      this.uiLayerMessageChannel.port2,
    ]);
  }

  destroy(uiLayerId: string) {
    this.createdFrameIds[uiLayerId]?.forEach((id) => {
      const iframe = document.querySelector(`iframe[name="${id}"]`);
      if (iframe) {
        iframe.remove();
      }
    });
  }

  updateAccountSession(values: AccountSessionClaim | 'error') {
    this.sendMessageToUILayer('claimresultupdate', values);
  }

  notifyOnboardingStateChanged(values: OnboardingStateValues) {
    this.sendMessageToUILayer('notifyonboardingstatechanged', values);
  }

  notifyLoggedOutStateChanged(values: LoggedOutStateValues) {
    this.sendMessageToUILayer('notifyloggedoutstatechanged', values);
  }

  notifyConnectJsOptionsChanged(values: UpdateOptions) {
    this.sendMessageToUILayer('updateinitoptionsto', values);
  }

  notifyPlatformViewportResize(values: {width: number; height: number}) {
    this.sendMessageToUILayer('platformviewportresize', values);
  }

  updateHTMLAttribute(values: Record<string, string>) {
    this.sendMessageToUILayer('updatehtmlattribute', values);
  }

  updateConnectElementSupplementalObjects(
    values: IConnectElementSupplementalObject,
  ) {
    this.sendMessageToUILayer('updatesupplementalobjects', values);
  }

  interactOutside() {
    this.sendMessageToUILayer('interactoutside');
  }

  scrollOutside() {
    this.sendMessageToUILayer('scrolloutside');
  }

  updateRenderedState(isRendered: boolean) {
    this.sendMessageToUILayer('updaterenderedstateto', {isRendered});
  }

  dataLayerLoaded() {
    this.sendMessageToUILayer('datalayerloaded');
  }

  updateSupplementalFunctionValues(
    values: IConnectElementSupplementalFunction,
  ) {
    const record: Partial<Record<SupplementalFunctionKey, boolean>> = {};

    getStronglyTypedEntries(values).forEach(([key, value]) => {
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      record[key] = !!value;
    });

    this.sendMessageToUILayer('updatesupplementalfunctions', record);
  }

  collectMobileFinancialConnectionsResult(values: {
    id: string;
    result: FinancialConnectionsSessionResult | CollectBankAccountTokenResult;
  }) {
    this.sendMessageToUILayer(
      'collectmobilefinancialconnectionsresult',
      values,
    );
  }
}
