/* eslint-disable @stripe-internal/embedded/no-restricted-globals */
import type {
  CustomFontOptions,
  FetchApiError,
  ISecureContextMetadata,
} from '@stripe-internal/connect-embedded-lib';
import {
  getEffectiveConnectionTypeFromNavigator,
  isStripeDomain,
  evaluateBrowserSecureContext,
} from '@stripe-internal/connect-embedded-lib';
import type {AppearanceOptions} from '@stripe-internal/embedded-theming';
import {Analytics, Reports} from '@sail/observability';
import type {IFrameMessenger} from '../data-layer-client/FrameMessenger';
import {FrameMessenger} from '../data-layer-client/FrameMessenger';
import {getCurrentScriptUrlContext} from '../utils/getCurrentScriptUrlContext';
import type {IDeferredPromise} from '../utils/DeferredPromise';
import {DeferredPromise} from '../utils/DeferredPromise';
import type {AccountSessionClaim} from '../data-layer-frame/types';
import {includeStripeJSIfRequired} from './utils/includeStripeJSIfRequired';
import type {IOnboardingState} from '../data-layer-client/OnboardingContext/OnboardingState';
import {OnboardingState} from '../data-layer-client/OnboardingContext/OnboardingState';
import type {IPlatformSpecifiedConnectJsOptions} from './ConnectJsOptions';
import {ConnectJsOptions} from './ConnectJsOptions';
import {
  componentNameMapping,
  isConnectElementTag,
} from './utils/connectElementTags';
import {getTestmodeLogger} from '../utils/getLogger';
import {livemodeFromPublishableKey} from '../utils/livemodeFromPublishableKey';
import type ConnectElementBase from './ConnectElementBase';
import {getInitOptionsOverride} from './getInitOptionsOverride';
import type {MetaOptions} from './ConnectJSInterface/InitAndUpdateOptionsTypes';
import {validateUpdateOptions} from './ConnectJSInterface/validateOptions';
import {calculateSupportedLocale} from '../intl/calculateSupportedLocale';
import type {IConnectInstancePublicInterface} from './ConnectJSInterface/ConnectInstancePublicInterface';
import {appearanceVariablesOrderedArray} from '../components/ThemingUtils/canary/utils/appearanceVariableOrderedArray';
import {withErrorReportingAndAnalytics} from '../data-layer-client/ErrorReportingAndAnalytics';
import {nextId} from './utils/ids';
import {FontLoader} from './fonts/FontLoader';
import {getLoadedFontFamilies} from '../utils/dom/getLoadedFontFamilies';
import {requestIdleCallbackPonyfill} from '../vendor/requestIdleCallback';
import {getObservabilityConfig} from './getObservabilityConfig';
import {getEagerDataLayerAndConnectInstanceId} from '../data-layer-client/buildDataLayerFrame';
import type {ILoggedOutState} from './LoggedOutState';
import {LoggedOutState} from './LoggedOutState';

export class Connect implements IConnectInstancePublicInterface {
  analytics: Analytics;

  reports: Reports;

  public onboardingState: IOnboardingState;

  public loggedOutState: ILoggedOutState;

  public connectJsOptions: ConnectJsOptions;

  public connectInstanceId;

  public frameMessenger: IFrameMessenger;

  public deferredAuthPromise: IDeferredPromise<AccountSessionClaim> =
    new DeferredPromise();

  public fontLoader: FontLoader;

  public browserSecureContext: ISecureContextMetadata;

  constructor(
    public publishableKey: string,
    private clientSecret?: string,
    metaOptions?: MetaOptions,
    appearance?: AppearanceOptions,
    locale?: string,
    public refreshSecretCallback?: () => Promise<string>,
    private fetchClientSecret?: () => Promise<string>,
    fonts?: CustomFontOptions,
  ) {
    // Specifying `fetchClientSecret` provides the same functionality as `refreshSecretCallback`
    if (fetchClientSecret) {
      this.refreshSecretCallback = fetchClientSecret;
    }
    const eagerClientSecretPromise = metaOptions?.eagerClientSecretPromise;
    if (metaOptions) {
      // remove as it's not used anywhere else, and can't be serialized when passing metaOptions to dataLayer
      delete metaOptions.eagerClientSecretPromise;
    }
    const eagerDataLayerAndInstanceId = getEagerDataLayerAndConnectInstanceId();
    if (eagerDataLayerAndInstanceId) {
      this.connectInstanceId =
        eagerDataLayerAndInstanceId.eagerConnectInstanceId;
    } else {
      this.connectInstanceId = nextId('connect-instance');
    }
    this.frameMessenger = new FrameMessenger(
      this.connectInstanceId,
      // Note: Since web components don't run in the context of the connect.js script
      // we capture and pass on connect.js script information here
      getCurrentScriptUrlContext(),
      publishableKey,
      metaOptions,
      this.refreshSecretCallback,
      eagerDataLayerAndInstanceId?.eagerDataLayer,
    );
    this.frameMessenger.init();

    // We use init overrides on initialization only
    const initOverrides = getInitOptionsOverride();
    this.connectJsOptions = new ConnectJsOptions(
      {
        appearance,
        locale,
        metaOptions,
        fonts,
      },
      initOverrides,
    );

    const observabilityConfig = getObservabilityConfig({
      frameMessenger: this.frameMessenger,
      metaOptions: this.connectJsOptions.getValues().metaOptions,
    });

    this.analytics = new Analytics(observabilityConfig);
    this.reports = new Reports(observabilityConfig);

    const localeMatch = calculateSupportedLocale(
      this.connectJsOptions.getValues().locale,
    );
    const initialConnectJsOptionsAnalytics =
      this.connectJsOptions.getPlatformSpecifiedValues();
    const connectJsOptionsAnalytics = {
      appearance: initialConnectJsOptionsAnalytics.appearance,
      locale: initialConnectJsOptionsAnalytics.locale,
      refreshClientSecret: this.refreshSecretCallback ? 'provided' : undefined,
      clientSecret: clientSecret ? 'provided' : undefined,
      fetchClientSecret: fetchClientSecret ? 'provided' : undefined,
      publishableKey: publishableKey ? 'provided' : undefined,
      specifiedFontFamiliesCount: fonts?.length ?? 'null',
      hasEagerClientSecretPromise: !!eagerClientSecretPromise,
    };

    this.browserSecureContext = evaluateBrowserSecureContext(
      livemodeFromPublishableKey(publishableKey, false),
    );

    const browserSecureContextAnalytics = {
      result: this.browserSecureContext.evaluation.result,
      blockError:
        this.browserSecureContext.evaluation.result === 'error'
          ? this.browserSecureContext.evaluation.blockError
          : undefined,
      warnError:
        this.browserSecureContext.evaluation.result === 'warn'
          ? this.browserSecureContext.evaluation.warnError
          : undefined,
      isAllowed: this.browserSecureContext.isAllowed,
      isLocalhost: this.browserSecureContext.isLocalhost,
      isSecureContext: this.browserSecureContext.isSecureContext,
    };

    logBrowserSecureContextResultToConsole(this.browserSecureContext);

    this.analytics.track('submerchant_surfaces_initialize', {
      connectJsPath: getCurrentScriptUrlContext().absoluteFolderPath,
      platformHostName: window.location.hostname,
      platformOrigin: window.location.origin,
      connectionEffectiveType:
        getEffectiveConnectionTypeFromNavigator(navigator),
      sdk: metaOptions?.sdk || false,
      sdkVersion: metaOptions?.sdkOptions?.sdkVersion,
      mobileSdk: metaOptions?.mobileSdk || false,
      mobileSdkVersion: metaOptions?.mobileSdkVersion,
      attemptedLocale: localeMatch.attemptedLocale,
      matchedLocale: localeMatch.matchedLocale,
      localeSource: initOverrides?.locale ? 'override' : localeMatch.source,
      localeMatch: localeMatch.match,
      connectJsOptions: JSON.stringify(connectJsOptionsAnalytics),
      viewport_width: window.innerWidth,
      viewport_height: window.innerHeight,
      loadedFontFamilies: getLoadedFontFamilies().join(', '),
      browserSecureContext: JSON.stringify(browserSecureContextAnalytics),
    });

    this.onboardingState = new OnboardingState();

    this.loggedOutState = new LoggedOutState();

    this.fontLoader = new FontLoader(
      this.analytics,
      this.publishableKey,
      fonts,
    );

    this.authenticate(eagerClientSecretPromise);
  }

  private authenticate = async (
    eagerClientSecretPromise?: Promise<string>,
  ): Promise<void> => {
    let authenticationResult: AccountSessionClaim;
    try {
      if (eagerClientSecretPromise || this.fetchClientSecret) {
        // We should validate that fetchClientSecret returns a string
        let clientSecretString: string | undefined;
        if (eagerClientSecretPromise != null) {
          clientSecretString = await eagerClientSecretPromise;
        } else if (this.fetchClientSecret) {
          clientSecretString = await this.fetchClientSecret();
        }
        if (typeof clientSecretString !== 'string') {
          const humanReadableError = `The fetchClientSecret function should always return the client_secret as a string. The function that was provided returned a different value type of ${typeof clientSecretString}, so authentication will fail in this session.`;

          getTestmodeLogger(
            !livemodeFromPublishableKey(this.publishableKey, true),
          ).error(humanReadableError);

          this.deferredAuthPromise.reject(
            new Error('invalid_client_secret_function', {
              cause: {
                type: 'account_session_create_error',
                message: humanReadableError,
              },
            }),
          );
          return;
        }
        if (!clientSecretString) {
          const humanReadableError =
            'The fetchClientSecret function returned an empty string, so authentication will fail in this session.';

          getTestmodeLogger(
            !livemodeFromPublishableKey(this.publishableKey, true),
          ).error(humanReadableError);
          this.deferredAuthPromise.reject(
            new Error('invalid_client_secret_function', {
              cause: {
                type: 'account_session_create_error',
                message: humanReadableError,
              },
            }),
          );
          return;
        }

        this.clientSecret = clientSecretString;
      }

      // Keeping this check as this.clientSecret is currently optional, but by this point we should have thrown error
      if (!this.clientSecret) {
        throw new Error('fetchClientSecret returns undefined');
      }

      authenticationResult = await withErrorReportingAndAnalytics(
        this.reports,
        this.connectJsOptions.getValues().metaOptions,
        this.analytics,
        'submerchant_surfaces_authenticate',
        this.frameMessenger.authenticate,
        this.clientSecret,
        {type: 'authError'},
      );

      this.deferredAuthPromise.resolve(authenticationResult);
      this.onboardingState.updateValues({
        isOnboardingComplete:
          authenticationResult.connected_account_details_submitted,
      });
    } catch (error) {
      this.deferredAuthPromise.reject(error);

      // We don't re-throw `error` because nothing will handle the exception,
      // and that will lead to unhandled rejection issues in testing.
      return;
    }

    // After authenticate - we will include StripeJS in the DOM if appropriate
    // This is a fire and forget done once the browser has available cycles
    // - we don't await this call intentionally
    const localAuthResult = authenticationResult;
    setTimeout(() => {
      requestIdleCallbackPonyfill(() => {
        includeStripeJSIfRequired(
          localAuthResult,
          this.analytics,
          this.reports,
          this.publishableKey,
        );
      });
    }, 5000); // We load StripeJS some time after initial load to prevent resource contention
  };

  // WARNING: Methods below this line are part of the ConnectJS public interface and should not be updated without careful consideration (i.e. backwards compatibility)

  /**
   * Creates a new element with the given tag name.
   */
  create = (tagName: string): HTMLElement | null => {
    // Transform the name if a transform is needed (i.e. payments -> stripe-connect-payments)
    const connectElementTagName =
      tagName in componentNameMapping ? componentNameMapping[tagName] : tagName;

    if (!isConnectElementTag(connectElementTagName)) {
      getTestmodeLogger(
        !livemodeFromPublishableKey(this.publishableKey, true),
      ).error(`<${tagName}> is an invalid element name`);
      return null;
    }

    // IMPORTANT: setConnector should be called immediately after creation,
    // before the element is added to the DOM (i.e. before the connectedCallback is called).
    const element = document.createElement(connectElementTagName);
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    (element as ConnectElementBase).setConnector(this);
    return element;
  };

  /**
   * Updates the Connect instance with the given options.
   */
  update = (options: unknown) => {
    validateUpdateOptions(
      options,
      livemodeFromPublishableKey(this.publishableKey, true),
    );

    this.connectJsOptions.updateValues({
      ...options,
    });
  };

  logout = async () => {
    try {
      await withErrorReportingAndAnalytics(
        this.reports,
        this.connectJsOptions.getValues().metaOptions,
        this.analytics,
        'submerchant_surfaces_logout',
        () => {
          // We update state to stop rendering all components
          this.loggedOutState.updateValues({
            loggedOut: true,
          });
          return this.frameMessenger.logout();
        },
        false,
        undefined,
        undefined,
        undefined,
        ['invalid_session', 'reauth_required'],
      );
    } catch (error) {
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      const status = (error as FetchApiError).error.httpStatus;
      if (status !== 401 && status !== 404) {
        throw new Error('login unsuccessful');
      }
    }
  };

  /**
   * Helper method to pull the latest initiailization options. Useful for the doc site, where we serialize these values
   * into a script
   */
  getCurrentConnectJSOptions = ():
    | IPlatformSpecifiedConnectJsOptions
    | undefined => {
    // We only allow internal callers to use this method
    if (!isStripeDomain(new URL(window.location.href))) {
      return undefined;
    }

    return this.connectJsOptions.getPlatformSpecifiedValues();
  };

  /**
   * Helper method to pull the latest initiailization options. Useful for the doc site, where we serialize these values
   * into a script
   */
  getConnectJSAppearanceOrder = (): string[] | undefined => {
    // We only allow internal callers to use this method
    if (!isStripeDomain(new URL(window.location.href))) {
      return undefined;
    }

    return appearanceVariablesOrderedArray;
  };

  /**
   * Helper method to set react sdk version for analytics
   */
  setReactSdkAnalytics = (version: string) => {
    this.connectJsOptions.updateValues({
      sdkOptions: {
        ...this.connectJsOptions.getPlatformSpecifiedValues().metaOptions
          ?.sdkOptions,
        reactSdkVersion: version,
      },
    });
  };
}

function logBrowserSecureContextResultToConsole(
  browserSecureContext: ISecureContextMetadata,
) {
  switch (browserSecureContext.evaluation.result) {
    case 'error':
      // eslint-disable-next-line no-console
      console.error(browserSecureContext.evaluation.blockError);
      break;
    case 'warn':
      // eslint-disable-next-line no-console
      console.warn(browserSecureContext.evaluation.warnError);
      break;
    default:
      break;
  }
}
