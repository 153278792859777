import type {ComponentType, DOMAttributes} from 'react';
import type React from 'react';
import type {MessageDescriptor} from '@sail/intl';
import type {
  SentryDSN,
  SentryTeamName,
} from '@stripe-internal/connect-embedded-lib';
import type {PaymentDetailsHtmlParams} from './ConnectElementDefinitions/payment-details';
import {PaymentDetailsConfig} from './ConnectElementDefinitions/payment-details';
import {AccountManagementConfig} from './ConnectElementDefinitions/account-management';
import {AccountOnboardingConfig} from './ConnectElementDefinitions/account-onboarding';
import {PayoutsConfig} from './ConnectElementDefinitions/payouts';
import {PayoutsListConfig} from './ConnectElementDefinitions/payouts-list';
import {BalancesConfig} from './ConnectElementDefinitions/balances';
import {TransactionsListConfig} from './ConnectElementDefinitions/transactions-list';
import {PaymentsListConfig} from './ConnectElementDefinitions/payments-list';
import {DisputesConfig} from './ConnectElementDefinitions/disputes';
import {InstantPayoutsConfig} from './ConnectElementDefinitions/instant-payouts';
import {DebugUtilsConfig} from './ConnectElementDefinitions/debug-utils';
import {NotificationsBannerConfig} from './ConnectElementDefinitions/notifications-banner';
import {PaymentMethodSettingsConfig} from './ConnectElementDefinitions/payment-method-settings';
import type {IssuingCardsListHtmlParams} from './ConnectElementDefinitions/issuing-cards-list';
import {IssuingCardsListConfig} from './ConnectElementDefinitions/issuing-cards-list';
import type {IssuingCardHtmlParams} from './ConnectElementDefinitions/issuing-card';
import {IssuingCardConfig} from './ConnectElementDefinitions/issuing-card';
import type {FinancialAccountHtmlParams} from './ConnectElementDefinitions/financial-account';
import {FinancialAccountConfig} from './ConnectElementDefinitions/financial-account';
import type {RecipientsHtmlParams} from './ConnectElementDefinitions/recipients';
import {RecipientsConfig} from './ConnectElementDefinitions/recipients';
import type {FinancialAccountTransactionsHtmlParams} from './ConnectElementDefinitions/financial-account-transactions';
import {FinancialAccountTransactionsConfig} from './ConnectElementDefinitions/financial-account-transactions';
import {CapitalFinancingPromotionConfig} from './ConnectElementDefinitions/capital-financing-promotion';
import {CapitalFinancingApplicationConfig} from './ConnectElementDefinitions/capital-financing-application';
import {CapitalFinancingConfig} from './ConnectElementDefinitions/capital-financing';
import {CapitalOverviewConfig} from './ConnectElementDefinitions/capital-overview';
import {ProductTaxCodeSelectorConfig} from './ConnectElementDefinitions/product-tax-code-selector';
import {TaxSettingsConfig} from './ConnectElementDefinitions/tax-settings';
import {TaxRegistrationsConfig} from './ConnectElementDefinitions/tax-registrations';
import {TaxThresholdMonitoringConfig} from './ConnectElementDefinitions/tax-threshold-monitoring';
import {DebugUIPreviewConfig} from './ConnectElementDefinitions/debug-ui-preview';
import {DebugUIConfig} from './ConnectElementDefinitions/debug-ui-config';
import {DebugUILibraryConfig} from './ConnectElementDefinitions/debug-ui-library';
import type {AppInstallHtmlParams} from './ConnectElementDefinitions/app-install';
import {AppInstallConfig} from './ConnectElementDefinitions/app-install';
import type {AppSettingsHtmlParams} from './ConnectElementDefinitions/app-settings';
import {AppSettingsConfig} from './ConnectElementDefinitions/app-settings';
import {AppViewportConfig} from './ConnectElementDefinitions/app-viewport';
import type {IAccountSessionComponents} from '../../data-layer-client/AuthContext/AuthContext';
import type {ConnectElementEventType} from './HtmlEventTypes';
import type {ConnectHtmlAttributeType} from './HtmlAttributes';
import type {Feature} from '../../data-layer-client/FeaturesContext';
import type {SupplementalFunctionKey} from '../ConnectElementSupplementalFunctions';
import type {SupplementalObjectKey} from '../ConnectElementSupplementalObjects';
import {DocumentsConfig} from './ConnectElementDefinitions/documents';
import type {EarningsChartHtmlParams} from './ConnectElementDefinitions/earnings-chart';
import {EarningsChartConfig} from './ConnectElementDefinitions/earnings-chart';
import type {HostedDashboardPreviewHtmlParams} from './ConnectElementDefinitions/debug-hosted-dashboard-preview';
import {HostedDashboardPreviewConfig} from './ConnectElementDefinitions/debug-hosted-dashboard-preview';
import {DebugComponentList} from './ConnectElementDefinitions/debug-component-list';
import {AccountManagementFormConfig} from './ConnectElementDefinitions/account-management-form';
import type {ReportingChartHtmlParams} from './ConnectElementDefinitions/reporting-chart';
import {ReportingChartConfig} from './ConnectElementDefinitions/reporting-chart';

export type ConnectElementMessages = {
  /**
   * Override for the title of the error shown if the connect element runs into a render error
   */
  errorHeader?: MessageDescriptor<never>;
  /**
   * Override for the error text shown if the connect element runs into a render error
   */
  errorText?: MessageDescriptor<never>;
};

export type ComponentTypeDynamicImport = {
  default: ComponentType;
};

export type ConnectElementHtmlAttributeConfig = {
  /**
   * Controls whether the HTML attribute is internal or not. Internal attributes are not exposed to the platform.
   * Non-internal attributes require API review.
   */
  isInternal: boolean;
};

export type ConnectElementConfigEnabledKeyExcludingInternal =
  | keyof IAccountSessionComponents;
export type ConnectElementConfigEnabledKey =
  | 'internal'
  | ConnectElementConfigEnabledKeyExcludingInternal;

export type EmbeddedComponentConfig = Record<
  ConnectElementConfigEnabledKey,
  EmbeddedComponentConfigValue
>;
export type EmbeddedComponentConfigValue = {
  enabled: boolean;
  features: Partial<Record<Feature, boolean>>;
};

/**
 * The release state for the component https://go/connect-embedded-release-states
 */
export type ReleaseState = 'internal' | 'preview' | 'ga';

type BaseSetterMethod = {
  isInternal: boolean;
  description: string;
};

/**
 * A method that simply sets an HTML attribute. It supports setting to `null` | `undefined` to remove the attribute
 */
export type AttributeSetterMethod = {
  attribute: ConnectHtmlAttributeType;
  type: 'AttributeSettingMethod';
} & BaseSetterMethod;

/**
 * A method that sets an event listener on the element. The event listener will wait for a particular html event to be issued
 * There can only be 1 listener per HTML event
 */
export type EventListenerSetterMethod = {
  event: ConnectElementEventType;
  type: 'EventListenerMethod';
} & BaseSetterMethod;

/**
 * A method that sets a function or object that can be used by the element (not though an HTML attribute)
 */
export type SupplementalFunctionSetterMethod = {
  type: 'SupplementalFunction';
  objectToSet: SupplementalFunctionKey;
} & BaseSetterMethod;

export type SupplementalObjectSetterMethod = {
  type: 'SupplementalObject';
  objectToSet: SupplementalObjectKey;
} & BaseSetterMethod;

export type CustomHtmlElementMethod =
  | AttributeSetterMethod
  | EventListenerSetterMethod
  | SupplementalFunctionSetterMethod
  | SupplementalObjectSetterMethod;

export interface ConnectElementConfig {
  /**
   * The React component that renders with this element
   */
  Component: () => Promise<ComponentTypeDynamicImport>;

  /**
   * Optional overrides for localized messages pertaining to this connect element
   */
  messageOverrides?: ConnectElementMessages;

  /**
   * The list of HTML attributes that the component supports
   */
  htmlAttributes?: Partial<
    Record<ConnectHtmlAttributeType, ConnectElementHtmlAttributeConfig>
  >;

  /**
   * The list of HTML events that the component emits
   */
  htmlEvents?: ConnectElementEventType[];

  /**
   * Client-side flag that can override whether the embedded component is enabled/disabled.
   *
   */
  enabledOverrideFlag?: string;

  /**
   * Determines what property on the AccountSessionClaimComponentFragment object is used to determine if the connect element is enabled/disabled.
   *
   * If you explicitly set this value to true, then the component is always enabled.
   */
  enabledKey: ConnectElementConfigEnabledKey;

  /**
   * Indicates the release state of component (internal, preview, or GA) https://go/connect-embedded-release-states
   */
  releaseState: ReleaseState;

  /**
   * Determines whether the connect element will require the account to be onboarded before booting
   */
  requiresOnboarding?: boolean;

  /**
   * Determines whether the connect embedded component requires authentication to be rendered
   */
  requiresAuthentication?: boolean;

  /**
   * This flag can be set to true if you want this component to ignore all theming values/overrides specified
   * when initializing connect.js.
   * Note: inherited styles like font family will still be honored with this option
   */
  ignoreThemingValues?: boolean;

  /**
   * This flag can be set to true if you want this component to control the background styling itself.
   * This is used for components that have a wrapping border + border radius.
   * Note: inherited styles like font family will still be honored with this option
   */
  componentControlledBackground?: boolean;

  /**
   * This flag disables the minWidth of the embedded component
   * If not set, minWidth will be MIN_WIDTH or 320px.
   */
  disableMinWidth?: boolean;

  /**
   * The maximum width of the custom element. The default and recommended value is no maximum width.
   */
  maxWidth?: number;

  /**
   * The owner of the connect element. This is used for error ownership (sentry) and metrics
   */
  teamOwner: SentryTeamName;

  /**
   * Specifies whether the component can take an inline property. If so, we should not show the component until
   * we have the inline property available to read.
   */
  optionalInlineOverlay?: boolean;

  /**
   * Custom element methods that are available to use
   */
  customHtmlElementMethods?: Record<string, CustomHtmlElementMethod>;

  /**
   * The list of expected and muted errors for the component. Note: add errors here if they are global to your component. If they are global to all components.
   * add them to `DEFAULT_GLOBALLY_EXPECTED_ERRORS`. If they are specific to a query, add those in the query only
   */
  errorConfiguration?: {
    expectedErrors?: string[];
    mutedErrors?: string[];
  };

  /**
   * The description of the component - this is a human readable string meant for developers using the component
   */
  description: string;

  /**
   * The path to the doc site page for a given component. For example, for the `stripe-connect-payments` component, the doc site path would be `/docs/stripe-connect-payments`
   * since the website is https://docs.corp.stripe.com/connect/supported-embedded-components/payments
   */
  docSitePath?: string;

  /**
   * Information about the implemented synthetic test for this component
   */
  syntheticTestInfo?: {
    /**
     * URL to the synthetic test for this component, i.e. https://amp.corp.stripe.com/synthetics/us-west-2/sus-payments-iframe?filterState=ALL
     */
    testUrl: string;
    /**
     * URL to the release candidate synthetic test for this component, i.e. https://amp.corp.stripe.com/synthetics/us-west-2/sus-paymentsiframe-rc?filterState=ALL
     */
    rcTestUrl?: string;
  };

  /**
   * Specifies whether a component should apply Sail overrides to better adapt components for mobile
   *
   * Note: currently experimental, do not turn on!
   */
  applyMobileUIOverrides?: boolean;

  /**
   * These fields are present when this component is deprecated (note deprecated components may still have usage)
   */
  deprecated?: {
    /**
     * Rationale for the component being deprecated. What should customers use instead?
     */
    deprecatedMessage: string;
  };
}

// Add your project name to frontend/connect/embedded-lib/src/utils/errors/getSentryTeam.ts
// Find the DSN under project Settings -> SDK Setup -> Client Keys (DSN)
export const accountsUIDSN: SentryDSN = {
  prod: 'https://6a9b51946de84c52b5c0c5ad058230a3@errors.stripe.com/1014', // PROD: https://sentry.corp.stripe.com/organizations/stripe/projects/accounts-ui-embedded/?project=1014
  qa: 'https://6efcaeb800c24403ae5cea9ba210db72@qa-errors.stripe.com/747', // QA: https://sentry.qa.corp.stripe.com/organizations/stripe/projects/accounts-ui-embedded/?project=747
};

const connectActivationExperiencesDSN: SentryDSN = {
  prod: 'https://d80c16c3b59744e292e05ecf82e8d14f@errors.stripe.com/1430', // PROD: https://sentry.corp.stripe.com/organizations/stripe/projects/connect-activation-experiences/?project=1430
  qa: 'https://dd0a610cb4be448986f84b0c604f2a69@qa-errors.stripe.com/978', // QA: https://sentry.qa.corp.stripe.com/organizations/stripe/projects/connect-activation-experiences/?project=978
};

const connectMerchantRiskDSN: SentryDSN = {
  prod: 'https://7fbb198ac857435fbed12ab6aea7fa07@errors.stripe.com/1187', // PROD: https://sentry.corp.stripe.com/organizations/stripe/projects/connect-merchant-risk/?project=1187
  qa: 'https://c3525bf5c75e44089df7640172d8641e@qa-errors.stripe.com/845', // QA: https://sentry.qa.corp.stripe.com/organizations/stripe/projects/connect-merchant-risk/?project=845
};

const connectOnboardingDSN: SentryDSN = {
  prod: 'https://aa9a1009979a485a83908dc4eb913d60@errors.stripe.com/869', // PROD: https://sentry.corp.stripe.com/organizations/stripe/projects/connect-onboarding/?project=869
  qa: 'https://1df6437310974b5690b5dc3134cad9c2@qa-errors.stripe.com/678', // QA: https://sentry.qa.corp.stripe.com/organizations/stripe/projects/connect-onboarding/?project=678
};

const appPlatformDSN: SentryDSN = {
  prod: 'https://8ecfcdc4439c49d599927d6a809884f2@errors.stripe.com/574', // PROD: https://sentry.corp.stripe.com/organizations/stripe/projects/tailor-js/?project=574
  qa: 'https://e79b27bd3cb8482782c2f2ca9f8a557b@qa-errors.stripe.com/773', // QA: https://sentry.qa.corp.stripe.com/organizations/stripe/projects/app-platform/?project=773
};

const connectDistributionInfraDSN: SentryDSN = {
  prod: 'https://19da6e896e914cab86072e56d00409ea@errors.stripe.com/1013', // PROD: https://sentry.corp.stripe.com/organizations/stripe/projects/connect-product-distribution-infra/?project=1013
  qa: 'https://e9820f8179d84d7b927e739968fa824c@qa-errors.stripe.com/782', // QA: https://sentry.qa.corp.stripe.com/organizations/stripe/projects/connect-product-distribution-infra/?project=782
};

const lpmAdoptionDSN: SentryDSN = {
  prod: 'https://c5e4ec25663542c5abab9b19bc6a2eea@errors.stripe.com/764', // PROD: https://sentry.corp.stripe.com/organizations/stripe/projects/lpm-adoption/?project=764
  qa: 'https://182f37e560ec428684709a77c2752abb@qa-errors.stripe.com/825', // QA: https://sentry.qa.corp.stripe.com/organizations/stripe/issues/?project=825
};

const mmsUserExperiences: SentryDSN = {
  prod: 'https://88dbb7aeb7844faf8367b124be618a37@errors.stripe.com/902',
  qa: 'https://ccbf6697940a4940a1f87b6c965b9813@qa-errors.stripe.com/668',
};

const riskExperienceDSN: SentryDSN = {
  prod: 'https://474878f282724c91addc0bd782366c3c@errors.stripe.com/494', // PROD: https://sentry.corp.stripe.com/organizations/stripe/projects/risk_experience/?project=494
  qa: 'https://dbec2996263b41e784b2eb17afe0e0d7@qa-errors.stripe.com/391', // QA: https://sentry.qa.corp.stripe.com/organizations/stripe/projects/risk_experience/?project=391
};

// These are risk experience's sentry projects because icore doesn't exist yet
const interventionsCoreDSN: SentryDSN = {
  prod: 'https://474878f282724c91addc0bd782366c3c@errors.stripe.com/494', // PROD: https://sentry.corp.stripe.com/organizations/stripe/projects/risk_experience/?project=494
  qa: 'https://dbec2996263b41e784b2eb17afe0e0d7@qa-errors.stripe.com/391', // QA: https://sentry.qa.corp.stripe.com/organizations/stripe/projects/risk_experience/?project=391
};

const capitalForPlatformsDSN: SentryDSN = {
  prod: 'https://06c9f1c2a35345b3be0a22276e35776c@errors.stripe.com/829', // PROD: https://sentry.corp.stripe.com/organizations/stripe/projects/capital-for-platforms-js/?project=829
  qa: 'https://d57c15b111ab457dafee9d61ae5bc7ee@qa-errors.stripe.com/606', // QA: https://sentry.qa.corp.stripe.com/organizations/stripe/projects/capital-for-platforms-js/?project=606
};

const efSurfacesDSN: SentryDSN = {
  prod: 'https://682ac19261e942e2936aa1f50297f61a@errors.stripe.com/1340', // PROD: https://sentry.corp.stripe.com/organizations/stripe/projects/ef-surfaces-js/?project=1340
  qa: 'https://a90b6412d87944a89b3988486b83752a@qa-errors.stripe.com/929', // QA: https://sentry.qa.corp.stripe.com/organizations/stripe/projects/ef-surfaces-js/?project=929
};

const taxReportingDSN: SentryDSN = {
  prod: 'https://3268f825654f42209ab17cb950343c05@errors.stripe.com/366',
  qa: 'https://e486f31e03b041a9af0ef45a896d8185@qa-errors.stripe.com/285',
};

const sailCoreDSN: SentryDSN = {
  prod: 'https://28219678049240a7b5ccd5c4fa8075fe@errors.stripe.com/992', // PROD: https://sentry.corp.stripe.com/organizations/stripe/projects/sail-core/?project=992
  qa: 'https://e78e80651cc645a1bab10afbcb4a7e28@qa-errors.stripe.com/853', // QA: https://sentry.qa.corp.stripe.com/organizations/stripe/projects/sail-core/?project=853
};

// https://trailhead.corp.stripe.com/docs/tax-on-connect/embedded-components/embedded-components-overview#sentry
const taxConnectEmbeddedComponentsDSN: SentryDSN = {
  prod: 'https://fd7fbe8598594f35806d6ba7e725be7b@errors.stripe.com/1207', // PROD: https://sentry.corp.stripe.com/organizations/stripe/projects/tax-connect-embedded-components-frontend/?project=1207
  qa: 'https://fe48aae7360446649e2ec700de5eb4bf@qa-errors.stripe.com/859', // QA: https://sentry.qa.corp.stripe.com/settings/stripe/projects/tax-connect-embedded-components-frontend/
};

const climateDSN: SentryDSN = {
  prod: 'https://2225b2409b274ba28f86dd1500df9b80@errors.stripe.com/400', // PROD: https://sentry.corp.stripe.com/stripe/climate-dashboard/
  qa: 'https://5de5a9ccbb5b4e61ba5f8b6d92b16e14@qa-errors.stripe.com/321', // QA: https://sentry.qa.corp.stripe.com/stripe/climate-dashboard/
};

const reportingConnectEmbeddedComponentsDSN: SentryDSN = {
  prod: 'https://63561184653544e79ccc7f30a5f0b29d@errors.stripe.com/1358', // PROD: https://sentry.corp.stripe.com/stripe/reporting-connect-embedded-components-frontend/
  qa: 'https://daff771ca5f04e39b02155a613a8d5a8@qa-errors.stripe.com/940', // QA: https://sentry.qa.corp.stripe.com/stripe/reporting-connect-embedded-components-frontend/
};

export const sentryTeams: Record<SentryTeamName, SentryDSN> = {
  accounts_ui: accountsUIDSN,
  // TODO(jackmaris) rename connect_marketplaces
  connect_marketplaces: connectActivationExperiencesDSN,
  connect_merchant_risk: connectMerchantRiskDSN,
  connect_onboarding: connectOnboardingDSN,
  connect_product_distribution_infra: connectDistributionInfraDSN,
  embedded_risk_resolution_path: interventionsCoreDSN,
  lpm_adoption: lpmAdoptionDSN,
  mms_user_experiences: mmsUserExperiences,
  risk_experience: riskExperienceDSN,
  tailor: appPlatformDSN,
  app_platform: appPlatformDSN,
  capital_for_platforms: capitalForPlatformsDSN,
  ef_surfaces: efSurfacesDSN,
  tax_reporting: taxReportingDSN,
  tax_connect_embedded_components: taxConnectEmbeddedComponentsDSN,
  climate: climateDSN,
  sail_core: sailCoreDSN,
  reporting_connect_embedded_components: reportingConnectEmbeddedComponentsDSN,
};

/**
 * If you add an item here, consider adding it to our AEL config. Adding to AEL ensures
 * that the metrics show in our SFX dashboard https://go/sus/sfx
 * AEL config is in the zoolander repo: https://git.corp.stripe.com/stripe-internal/zoolander/blob/0d0cdac1a6482d92593637d835040e702d6f7f66/uppsala/src/main/resources/com/stripe/dscore/analyticseventlogger/server/rpcserver/client_config.yaml#L1380
 */
export type ConnectElementImportKeys =
  // Backwards compat keys
  | 'stripe-instant-payouts-experience'
  // New keys
  | 'stripe-connect-transactions-list'
  | 'stripe-connect-payments'
  | 'stripe-connect-payment-details'
  | 'stripe-connect-payouts'
  | 'stripe-connect-payouts-list'
  | 'stripe-connect-balances'
  | 'stripe-connect-account-management'
  | 'stripe-connect-account-management-form'
  | 'stripe-connect-account-onboarding'
  | 'stripe-connect-disputes'
  | 'stripe-connect-instant-payouts'
  | 'stripe-connect-notification-banner'
  | 'stripe-connect-debug-utils'
  | 'stripe-connect-debug-ui-config'
  | 'stripe-connect-debug-ui-library'
  | 'stripe-connect-debug-ui-preview'
  | 'stripe-connect-debug-components-list'
  | 'stripe-connect-debug-hosted-dashboard-preview'
  | 'stripe-connect-app-onboarding'
  | 'stripe-connect-app-install'
  | 'stripe-connect-app-viewport'
  | 'stripe-connect-payment-method-settings'
  | 'stripe-connect-capital-financing'
  | 'stripe-connect-capital-financing-application'
  | 'stripe-connect-capital-financing-promotion'
  | 'stripe-connect-capital-overview'
  | 'stripe-connect-app-settings'
  | 'stripe-connect-issuing-cards-list'
  | 'stripe-connect-issuing-card'
  | 'stripe-connect-financial-account'
  | 'stripe-connect-financial-account-transactions'
  | 'stripe-connect-recipients'
  | 'stripe-connect-product-tax-code-selector'
  | 'stripe-connect-tax-threshold-monitoring'
  | 'stripe-connect-tax-settings'
  | 'stripe-connect-tax-registrations'
  | 'stripe-connect-documents'
  | 'stripe-connect-earnings-chart'
  | 'stripe-connect-reporting-chart';

export const CONNECT_ELEMENT_IMPORTS: Record<
  ConnectElementImportKeys,
  ConnectElementConfig
> = {
  'stripe-connect-payments': PaymentsListConfig,
  'stripe-connect-payment-details': PaymentDetailsConfig,
  'stripe-connect-payouts': PayoutsConfig,
  'stripe-connect-account-management': AccountManagementConfig,
  'stripe-connect-account-onboarding': AccountOnboardingConfig,
  'stripe-connect-notification-banner': NotificationsBannerConfig,
  'stripe-connect-product-tax-code-selector': ProductTaxCodeSelectorConfig,
  'stripe-connect-tax-threshold-monitoring': TaxThresholdMonitoringConfig,
  'stripe-connect-tax-settings': TaxSettingsConfig,
  'stripe-connect-tax-registrations': TaxRegistrationsConfig,

  // Components in preview
  'stripe-connect-instant-payouts': InstantPayoutsConfig,

  // Internal only components
  'stripe-connect-debug-utils': DebugUtilsConfig,
  'stripe-connect-debug-ui-config': DebugUIConfig,
  'stripe-connect-debug-ui-preview': DebugUIPreviewConfig,
  'stripe-connect-debug-ui-library': DebugUILibraryConfig,
  'stripe-connect-debug-hosted-dashboard-preview': HostedDashboardPreviewConfig,
  'stripe-connect-debug-components-list': DebugComponentList,

  // Other unreleased components
  'stripe-connect-disputes': DisputesConfig,
  'stripe-connect-transactions-list': TransactionsListConfig,
  'stripe-connect-payouts-list': PayoutsListConfig,
  'stripe-connect-balances': BalancesConfig,
  'stripe-connect-payment-method-settings': PaymentMethodSettingsConfig,
  'stripe-connect-capital-financing': CapitalFinancingConfig,
  'stripe-connect-capital-financing-application':
    CapitalFinancingApplicationConfig,
  'stripe-connect-capital-financing-promotion': CapitalFinancingPromotionConfig,
  'stripe-connect-capital-overview': CapitalOverviewConfig,
  'stripe-connect-issuing-cards-list': IssuingCardsListConfig,
  'stripe-connect-issuing-card': IssuingCardConfig,
  'stripe-connect-financial-account': FinancialAccountConfig,
  'stripe-connect-recipients': RecipientsConfig,
  'stripe-connect-financial-account-transactions':
    FinancialAccountTransactionsConfig,
  'stripe-connect-documents': DocumentsConfig,
  'stripe-connect-earnings-chart': EarningsChartConfig,
  'stripe-connect-account-management-form': AccountManagementFormConfig,
  'stripe-connect-reporting-chart': ReportingChartConfig,

  // Stripe App components
  'stripe-connect-app-onboarding': AppInstallConfig,
  'stripe-connect-app-install': AppInstallConfig,
  'stripe-connect-app-settings': AppSettingsConfig,
  'stripe-connect-app-viewport': AppViewportConfig,

  // TODO (ACCOUNTS-UI:) Remove old names we are keeping for backwards compatibility
  'stripe-instant-payouts-experience': InstantPayoutsConfig,
};

export type CustomElement<T> = Partial<
  T &
    DOMAttributes<T> & {
      children: HTMLElement[];
      ref: React.RefObject<HTMLElement>;
    }
>;

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      ['stripe-instant-payouts-experience']: CustomElement<
        Record<string, unknown>
      >;
      ['stripe-connect-transactions-list']: CustomElement<
        Record<string, unknown>
      >;
      ['stripe-connect-payments']: CustomElement<Record<string, unknown>>;
      ['stripe-connect-payouts']: CustomElement<Record<string, unknown>>;
      ['stripe-connect-payouts-list']: CustomElement<Record<string, unknown>>;
      ['stripe-connect-balances']: CustomElement<Record<string, unknown>>;
      ['stripe-connect-account-management']: CustomElement<
        Record<string, unknown>
      >;
      ['stripe-connect-account-onboarding']: CustomElement<
        Record<string, unknown>
      >;
      ['stripe-connect-payment-details']: CustomElement<PaymentDetailsHtmlParams>;
      ['stripe-connect-disputes']: CustomElement<Record<string, unknown>>;
      ['stripe-connect-instant-payouts']: CustomElement<
        Record<string, unknown>
      >;
      ['stripe-connect-notification-banner']: CustomElement<
        Record<string, unknown>
      >;
      ['stripe-connect-debug-ui-config']: CustomElement<
        Record<string, unknown>
      >;
      ['stripe-connect-debug-ui-preview']: CustomElement<
        Record<string, unknown>
      >;
      ['stripe-connect-debug-ui-library']: CustomElement<
        Record<string, unknown>
      >;
      ['stripe-connect-debug-components-list']: CustomElement<
        Record<string, unknown>
      >;
      ['stripe-connect-debug-hosted-dashboard-preview']: CustomElement<HostedDashboardPreviewHtmlParams>;
      // TODO (chaves) - deprecate app_onboarding APPLCM-1214
      ['stripe-connect-app-onboarding']: CustomElement<AppInstallHtmlParams>;
      ['stripe-connect-app-install']: CustomElement<AppInstallHtmlParams>;
      ['stripe-connect-app-settings']: CustomElement<AppSettingsHtmlParams>;
      ['stripe-connect-app-viewport']: CustomElement<Record<string, unknown>>;
      ['stripe-connect-payment-method-settings']: CustomElement<
        Record<string, unknown>
      >;
      ['stripe-connect-capital-overview']: CustomElement<
        Record<string, unknown>
      >;
      ['stripe-connect-capital-financing']: CustomElement<
        Record<string, unknown>
      >;
      ['stripe-connect-capital-financing-application']: CustomElement<
        Record<string, unknown>
      >;

      ['stripe-connect-issuing-cards-list']: CustomElement<IssuingCardsListHtmlParams>;
      ['stripe-connect-issuing-card']: CustomElement<IssuingCardHtmlParams>;
      ['stripe-connect-financial-account']: CustomElement<FinancialAccountHtmlParams>;
      ['stripe-connect-financial-account-transactions']: CustomElement<FinancialAccountTransactionsHtmlParams>;
      ['stripe-connect-recipients']: CustomElement<RecipientsHtmlParams>;
      ['stripe-connect-product-tax-code-selector']: CustomElement<
        Record<string, unknown>
      >;
      ['stripe-connect-tax-threshold-monitoring']: CustomElement<
        Record<string, unknown>
      >;
      ['stripe-connect-tax-settings']: CustomElement<Record<string, unknown>>;
      ['stripe-connect-tax-registrations']: CustomElement<
        Record<string, unknown>
      >;
      ['stripe-connect-documents']: CustomElement<Record<string, unknown>>;
      ['stripe-connect-earnings-chart']: CustomElement<EarningsChartHtmlParams>;
      ['stripe-connect-account-management-form']: CustomElement<
        Record<string, unknown>
      >;
      ['stripe-connect-reporting-chart']: CustomElement<ReportingChartHtmlParams>;
    }
  }
}
